
.scrollview {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
}

.bg {
  width: 100%;
  height: 100%;
  position: fixed;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.fg {
  display: flex;
  width: 100%;
  height: 100%;
  position: fixed;
}

.bg0 {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (min-width: 1500px) {
  .bg0 {
    -webkit-transform: translateY(calc((100% - 100vw) / 2 + 200px));
            transform: translateY(calc((100% - 100vw) / 2 + 200px));
  }
}

video {
  width: 100%;
  min-width: 100vh;
  height: auto;
}
.web3modal-modal-lightbox {
  z-index: 100 !important;
}

header {
  display: flex;
  flex-direction: row;
  position: fixed;
  justify-content: space-between;
  width: 100%;
  font-weight: bold;
  font-size: 0.9em;
  padding: 0 60px 0 10px;
  z-index: 999;
}
@media (max-width: 630px) {
  header {
    flex-direction: column;
    font-size: 18px;
    padding: 1em 1em 0.7em 1em;
  }
  header.active {
    height: 100%;
    background: #E5E5E5;
  }
  header.active nav {
    display: flex;
  }
}

.header-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;
}

.header-links {
  flex-direction: row;
  justify-content: space-between;
}
@media (min-width: 630px) {
  .header-links {
    display: flex;
  }
}

.opaque {
  background-color: #F2EEEC;
}

.header-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-logo {
  width: 8em;
}
@media (max-width: 630px) {
  .header-logo {
    height: 40px;
    width: 76px;
  }
}

.header-hamb {
  display: none;
  background-image: url(/static/media/hamb.d41d8cd9.svg);
  background-size: 100% 100%;
  width: 24px;
  height: 24px;
}
@media (max-width: 630px) {
  .header-hamb {
    display: inline;
  }
}

nav {
  display: flex;
  align-items: center;
  color: inherit;
  justify-content: space-between;
}
@media (max-width: 630px) {
  nav {
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    height: 100%;
    display: none;
    margin-left: 0;
  }
}

.nav-connect-link {
  width: 100%;
  margin-left: 15px;
  color: inherit;
}
@media (max-width: 630px) {
  .nav-connect-link {
    width: 100%;
    margin-left: 0;
  }
}
.nav-connect-link button {
  width: 100%;
  cursor: pointer;
  padding: 10px;
}

.nav-links {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  width: 100%;
  text-decoration: none;
  text-transform: uppercase;
}
@media (max-width: 630px) {
  .nav-links {
    flex-direction: column;
    width: 100%;
  }
}

.nav-link {
  display: flex;
  color: inherit;
  margin: 0 10px;
  text-decoration: inherit;
  white-space: nowrap;
}
@media (max-width: 630px) {
  .nav-link {
    width: 100%;
    margin: 0;
    border-bottom: 1px solid #000;
    margin: 0.4em 0;
    justify-content: space-between;
  }
  .nav-link svg {
    display: inline;
    margin-left: auto;
  }
}
.nav-link svg {
  display: none;
  margin: auto 0;
}
.nav-link svg.show {
  display: inline;
  margin-left: 0.6em;
}

.nav-story {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.nav-story-btn {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.nav-story-popup {
  display: flex;
  border-radius: 3px;
  position: fixed;
  -webkit-transform: translateY(25px);
          transform: translateY(25px);
  background: rgba(0, 0, 0, 0.4);
  color: #F2EEEC;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 4px;
  padding-bottom: 0;
  margin-top: 40px;
  -webkit-transform: translateX(-42%);
          transform: translateX(-42%);
}
@media (max-width: 630px) {
  .nav-story-popup {
    position: static;
    -webkit-transform: none;
            transform: none;
    background: none;
    box-shadow: none;
    color: #000;
    margin: 0;
    padding: 0;
    flex-direction: column;
  }
}
header.light .nav-story-popup {
  background: #E5E5E5;
  color: #000;
}

.nav-story-image {
  height: 160px;
  width: 219.84px;
  background-size: auto 100%;
  background-position: 50%;
  border-radius: 4px;
  flex-shrink: 0;
}
@media (max-width: 630px) {
  .nav-story-image {
    height: 80px;
    width: 109.92px;
  }
}
.nav-story-image.-vault {
  background-image: url(/static/media/interior1.b41bf060.jpg);
}
.nav-story-image.-glimpses {
  background-image: url(/static/media/glimpses1.3c141586.png);
}

.nav-story-link {
  display: flex;
  flex-direction: column;
  margin: 14px;
  color: inherit;
  text-decoration: none;
}
@media (max-width: 630px) {
  .nav-story-link {
    flex-direction: row;
    align-items: center;
    margin: 10px 0;
    max-width: 90vw;
  }
}

.nav-story-label {
  margin-top: 10px;
  width: 100%;
}
@media (max-width: 630px) {
  .nav-story-label {
    margin: auto 1em;
    font-size: 12px;
  }
}

.wallet-address {
  font-size: 10px;
  align-self: flex-end;
  margin: 0;
  padding: 0;
}

.wallet-placeholder {
  padding: 13px;
}
.web3modal-modal-lightbox {
  z-index: 100 !important;
}

.main-page {
  background: #E5E5E5;
}

.top-fg {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.announcement-logo {
  width: 25vw;
  height: auto;
  max-width: 500px;
  min-width: 200px;
  margin-top: 200px;
}

.text {
  display: flex;
  flex-direction: column;
  line-height: 1.3;
  margin-right: 28px;
}
.text button {
  color: black;
  border-color: black;
  width: 100%;
  text-decoration: none;
  cursor: pointer;
}

.announcement-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.announcement {
  max-width: 550px;
  margin: 1.4em;
  align-self: center;
  padding: 1.4em;
  color: black;
  background: rgba(2, 0, 0, 0.6);
  color: #F2EEEC;
  border-radius: 3px;
  line-height: 1.3;
  margin-bottom: 100px;
  text-align: center;
}
@media (max-width: 630px) {
  .announcement {
    font-size: 12px;
  }
}
.announcement button {
  width: 100%;
  color: #303030;
  background-color: #F2EEEC;
}

.mobile-invisible {
  display: block;
}
@media (max-width: 630px) {
  .mobile-invisible {
    display: none;
  }
}

.mobile-visible {
  display: none;
}
@media (max-width: 630px) {
  .mobile-visible {
    display: block;
  }
}

.description {
  max-width: 750px;
  align-self: center;
  width: 70vw;
  padding: 30px;
  font-size: 16px;
  line-height: 1.1;
}
@media (max-width: 630px) {
  .description {
    font-size: 16px;
    line-height: 1;
  }
}
.description h1 {
  font-size: 53px;
  line-height: 1;
}
@media (max-width: 1440px) {
  .description h1 {
    font-size: 35px;
  }
}
@media (max-width: 630px) {
  .description h1 {
    font-size: 25px;
  }
}
@media (max-width: 640px) {
  .description h1 {
    font-size: 20px;
  }
}
@media (max-width: 440px) {
  .description h1 {
    font-size: 18px;
  }
}
.description h2 {
  font-size: 30px;
  font-weight: 300;
  margin-bottom: 1vw;
}

.latest {
  justify-self: flex-end;
}

.about-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 80vw;
  padding-top: 50px;
}

.about-tech-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  justify-content: center;
  width: 80vw;
  margin-left: 25vw;
}

.about {
  margin: 6em;
  width: 100%;
  max-width: 560px;
  color: #F2EEEC;
  padding: 1.4em;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 3px;
  align-self: flex-start;
}
@media (max-width: 630px) {
  .about {
    margin: 2em;
    margin-top: 7em;
    max-width: 370px;
  }
}

.vault-teaser {
  margin: 4em 6em;
  justify-content: center;
  align-items: center;
  color: #F2EEEC;
  max-width: 800px;
}
@media (max-width: 630px) {
  .vault-teaser {
    margin: 2em;
    margin-top: 2em;
  }
}

.legal {
  position: fixed;
  bottom: 0px;
  right: 0px;
  padding: 20px;
  font-size: 12px;
}
.legal .copyrights {
  text-align: right;
  padding-right: 10px;
}
.legal .legal-link {
  color: #F2EEEC;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  text-decoration: none;
}
@media (max-width: 630px) {
  .legal {
    font-size: 11px;
  }
  .legal .legal-link {
    padding-left: 2px;
    padding-right: 2px;
  }
}

.bg4 {
  background-image: url(/static/media/interior3_cropped.f41254cd.jpg);
}

.bg3 {
  height: 200%;
  width: 55%;
  max-width: 1200px;
  margin-right: 0px;
  align-self: flex-end;
  background-size: 100% auto;
  background-position: bottom -100px right 0px;
  background-image: url(/static/media/boat.b27809cd.png);
}
@media (max-width: 1440px) {
  .bg3 {
    background-position: bottom -100px right -150px;
    width: 70%;
  }
}
@media (max-width: 1024px) {
  .bg3 {
    background-position: bottom -100px right -350px;
    width: 110%;
  }
}
@media (max-width: 768px) {
  .bg3 {
    background-position: bottom -200px right -350px;
    width: 130%;
  }
}
@media (max-width: 600px) {
  .bg3 {
    background-position: bottom -120px right -250px;
    width: 140%;
  }
}
@media (max-width: 500px) {
  .bg3 {
    background-position: bottom -120px right -200px;
    width: 170%;
  }
}

.bg2 {
  height: 250%;
  width: 100%;
  align-self: flex-end;
  background-size: cover;
  background-position: 50% 80%;
  background-image: url(/static/media/BG_sky_brushstroke_006.82869a95.png);
}
.bg1 {
  background-color: #F2EEEC;
}
.web3modal-modal-lightbox {
  z-index: 100 !important;
}

.glimpses-page {
  position: relative;
  width: 100%;
  height: 100%;
}

.glimpses-fg {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 2em;
  padding-top: 6em;
  z-index: 10;
  height: 100%;
  width: 100%;
}
@media (max-width: 630px) {
  .glimpses-fg {
    padding: 3em 2em 0 2em;
  }
}
.glimpses-fg h1 {
  font-size: 3em;
  text-align: center;
}
.glimpses-fg .text {
  max-width: 500px;
  font-size: 1.2em;
  color: rgb(255, 255, 255);
  border-radius: 4px;
  line-height: 1.3;
  margin: 10px;
  text-align: center;
}
@media (max-width: 630px) {
  .glimpses-fg .text {
    font-size: 0.9em;
  }
}
.glimpses-fg .items {
  display: flex;
  width: 100%;
  min-height: 260px;
  max-width: 50vw;
  margin: 100px 0;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 0 20px 0;
}
@media (max-width: 2200px) {
  .glimpses-fg .items {
    max-width: 70vw;
  }
}
@media (max-width: 1024px) {
  .glimpses-fg .items {
    max-width: 90vw;
    margin: 10px;
    justify-content: center;
  }
}
.glimpses-fg .items .item {
  width: 340px;
  background-color: #F2EEEC;
  display: flex;
  flex-direction: column;
  margin: 10px;
  border-radius: 4px;
  padding: 1em;
  justify-content: space-between;
}
.glimpses-fg .items .item .item-image {
  height: 180px;
  width: 100%;
  background-size: auto 100%;
  background-position: 50%;
  border-radius: 4px;
}
.glimpses-fg .items .item h1 {
  font-size: 1rem;
  text-transform: none;
  text-align: left;
}
.glimpses-fg .items .item button {
  color: black;
  border-color: black;
  width: 100%;
}

.glimpses-bg1 {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-image: url(/static/media/Glimpses_BG_001.2cd12b91.jpg);
  background-size: auto 100%;
  background-position: 50% 100%;
  background-repeat: no-repeat;
}
.web3modal-modal-lightbox {
  z-index: 100 !important;
}

.glimpse-detail-page {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #F2EEEC;
}

.glimpse-detail-fg {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2em;
  padding-top: 6em;
  z-index: 10;
  height: 100%;
  width: 100%;
}
@media (max-width: 630px) {
  .glimpse-detail-fg {
    padding-top: 2em;
  }
}
.glimpse-detail-fg .text {
  width: 80vw;
  max-width: 800px;
  font-size: 1.1em;
  color: #303030;
  padding: 10px;
  border-radius: 4px;
  line-height: 1.3;
}
.glimpse-detail-fg .text h1 {
  max-width: 90vw;
  font-size: 2em;
  text-align: left;
  text-transform: none;
  padding-top: 30px;
  line-height: normal;
}
@media (max-width: 630px) {
  .glimpse-detail-fg .text {
    width: 80vw;
  }
}
.glimpse-detail-fg .glimpse-image {
  padding: 10px 20px 20px 10px;
}

.glimpse-detail-bg1 {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-image: url(/static/media/Glimpses_BG_001.2cd12b91.jpg);
  background-size: auto 100%;
  background-position: 50% 100%;
  background-repeat: no-repeat;
}
.web3modal-modal-lightbox {
  z-index: 100 !important;
}

.vault-page {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #F2EEEC;
}

.vault-fg {
  display: flex;
  position: absolute;
  z-index: 10;
  padding: 2em;
  padding-top: 7em;
  width: 100vw;
  justify-content: center;
}
@media (max-width: 1000px) {
  .vault-fg {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
}

.vault-st {
  display: flex;
  flex-direction: column;
}

.vault-st-list {
  display: flex;
  flex-direction: column;
  width: calc(100vw - 4em);
  max-width: 800px;
  margin-top: 1em;
  align-items: center;
}

.vault-st-event {
  display: flex;
  flex-direction: column;
  width: 50vw;
  min-width: 260px;
  max-width: 440px;
  background: #E5E5E5;
  padding: 1em;
  border-radius: 4px;
  margin: 10px 10px 20px 0px;
}

.vault-st-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 300px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 100%;
}
.vault-st-image.-interior1 {
  background-image: url(/static/media/interior1.b41bf060.jpg);
}
.vault-st-image.-interior2 {
  background-image: url(/static/media/interior2.2718a851.jpg);
}
.vault-st-image.-interior3 {
  background-image: url(/static/media/interior3_cropped.f41254cd.jpg);
}
.vault-st-image.-interior4 {
  background-image: url(/static/media/interior4.64b78b9f.jpg);
}
.vault-st-image.-interior5 {
  background-image: url(/static/media/Part05_Cover_Hidden.3c89b528.png);
}
.vault-st-image.-interior6 {
  background-image: url(/static/media/Part05_Cover.0f50b5af.png);
}
.vault-st-image.-interior7 {
  background-image: url(/static/media/Part06_Cover_small_dark.a9a7d5e7.png);
}

.vault-st-name {
  color: #E5E5E5;
}

.vault-st-btn {
  color: #E5E5E5;
  border-color: #E5E5E5;
  cursor: pointer;
}
@media (max-width: 1000px) {
  .vault-st-btn {
    background-color: rgba(62, 62, 61, 0.3);
  }
}

.vault-st-text {
  display: flex;
  flex-direction: column;
}
.vault-st-text button {
  color: #303030;
  border-color: #303030;
  cursor: pointer;
  margin-top: 10px;
}

.decide-btn {
  align-self: center;
}

.vault-st-indicators {
  display: flex;
  height: 5px;
  margin: 1.5em auto;
}

.vault-st-indicator {
  margin: 2px;
  width: 18px;
  height: 3px;
  border-radius: 6px;
  background-color: #B1B1B1;
}
.vault-st-indicator.-active {
  background-color: #C5A839;
}

.vault-timeline {
  display: grid;
  grid-template-columns: auto 20px minmax(200px);
  grid-template-rows: auto auto auto auto auto auto auto auto auto auto auto auto auto;
  grid-gap: 1em;
  max-width: 1000px;
}
.vault-timeline button {
  color: #303030;
  border-color: #303030;
  cursor: pointer;
}

.vault-text-original {
  position: -webkit-sticky;
  position: sticky;
  top: 115px;
  grid-column: 1;
  grid-row: 1/4;
  max-width: 600px;
  line-height: 1.3;
}
.vault-text-original h1 {
  margin: 0 0 15px 0;
}
@media (min-width: 1000px) {
  .vault-text-original {
    padding-right: 100px;
    align-self: start;
  }
  .vault-text-original h1 {
    font-size: 65px;
    line-height: 0.8;
  }
}
.vault-text-original button {
  color: #303030;
  border-color: #303030;
  cursor: pointer;
}
@media (max-width: 1000px) {
  .vault-text-original {
    position: static;
    max-width: 500px;
    top: 1em;
    color: #E5E5E5;
    padding-top: 30px;
  }
  .vault-text-original button {
    border-color: #E5E5E5;
    cursor: pointer;
    color: #F2EEEC;
    width: 100%;
    background-color: rgba(242, 238, 236, 0.3);
  }
}

.vault-event-image {
  width: 350px;
  height: 249px;
  background-size: cover;
  background-position: 50% 50%;
  border-radius: 3px;
  grid-column: 2;
  grid-row: auto;
  margin: auto 0;
}
@media (max-width: 1000px) {
  .vault-event-image {
    grid-column: 1;
    grid-row: 3;
    width: 100%;
    height: 100%;
  }
}
.vault-event-image.-interior1 {
  background-image: url(/static/media/interior1.b41bf060.jpg);
}
.vault-event-image.-interior2 {
  background-image: url(/static/media/interior2.2718a851.jpg);
}
.vault-event-image.-interior3 {
  background-image: url(/static/media/interior3_cropped.f41254cd.jpg);
}
.vault-event-image.-interior4 {
  background-image: url(/static/media/interior4.64b78b9f.jpg);
}
.vault-event-image.-interior6 {
  background-image: url(/static/media/Part05_Cover.0f50b5af.png);
}
.vault-event-image.-interior7 {
  background-image: url(/static/media/Part06_Cover_small.6c69eb6d.png);
}

.vault-event-dot {
  grid-column: 3;
  grid-row: 1/6;
  width: 20px;
  height: 20px;
  background-size: cover;
  background-image: url(/static/media/vault_event_dot.fb925b5d.svg);
  z-index: 4;
  margin: auto 0;
}
@media (max-width: 1000px) {
  .vault-event-dot {
    display: none;
  }
}

.dotted-line {
  grid-column: 3;
  grid-row: 2/-1;
  border-left: 2px dashed #000;
  margin: 0 auto;
  z-index: 3;
}
@media (max-width: 1000px) {
  .dotted-line {
    display: none;
  }
}

.vault-event-note {
  grid-column: 4;
  margin: auto 0;
  margin-right: auto;
  border-radius: 3px;
  padding: 1em;
  background-color: rgba(242, 238, 236, 0.8);
  z-index: 7;
  min-width: 50%;
  width: 100%;
  line-height: 1.3;
}
@media (max-width: 1000px) {
  .vault-event-note {
    grid-column: 1;
    grid-row: 3;
  }
}
.vault-event-note button {
  color: black;
}
.vault-event-note a:visited {
  color: black;
}
.vault-event-note .new {
  color: #ECBC00;
}
.vault-event-note h1 {
  font-size: 1em;
}
.vault-event-note.-brushed {
  background-color: transparent;
}
@media (max-width: 630px) {
  .vault-event-note {
    margin-left: 0;
  }
}

.vault-event-note-nobg {
  grid-column: 4;
  margin: auto 0;
  margin-right: auto;
  border-radius: 3px;
  padding: 1em;
  z-index: 7;
  min-width: 50%;
}
@media (max-width: 1000px) {
  .vault-event-note-nobg {
    grid-column: 1;
    grid-row: 3;
  }
}
.vault-event-note-nobg button {
  color: black;
}
.vault-event-note-nobg a:visited {
  color: black;
}
.vault-event-note-nobg .new {
  color: #ECBC00;
}
.vault-event-note-nobg h1 {
  font-size: 1em;
}
.vault-event-note-nobg.-brushed {
  background-color: transparent;
}
@media (max-width: 630px) {
  .vault-event-note-nobg {
    margin-left: 0;
  }
}

.vault-brush-stroke {
  height: 180px;
  grid-column: 2/5;
  background-size: contain;
  background-position: 70% 50%;
  background-repeat: no-repeat;
  background-image: url(/static/media/brushtroke_gray.7e125ae0.png);
  -webkit-transform: rotate(4deg);
          transform: rotate(4deg);
}

.vault-row-1 {
  grid-row: 1/3;
}

.vault-row-2 {
  grid-row: 3/5;
}

.vault-row-3 {
  grid-row: 5/7;
}

.vault-row-4 {
  grid-row: 7/9;
}

.vault-row-5 {
  grid-row: 9/11;
}

.vault-row-6 {
  grid-row: 11/13;
}

.vault-row-7 {
  grid-row: 13/15;
}

.vault-bg1 {
  position: fixed;
  bottom: 0;
  left: 5%;
  width: 293px;
  height: 465px;
  z-index: 3;
  background-image: url(/static/media/layer1.5594bb65.png);
  background-size: 100% 100%;
}
@media (max-width: 1000px) {
  .vault-bg1 {
    display: none;
  }
}

.vault-bg2 {
  position: fixed;
  bottom: 20%;
  left: 20%;
  width: 173px;
  height: 91px;
  z-index: 2;
  background-image: url(/static/media/layer2.d5b8949e.png);
  background-size: 100% 100%;
}
@media (max-width: 1000px) {
  .vault-bg2 {
    display: none;
  }
}

.vault-bg3 {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 92%;
  background-size: auto 100%;
  background-position: 50% 100%;
  background-repeat: no-repeat;
  background-image: url(/static/media/vault_of_ancients_bg_cutout.e396e8fc.png);
}
@media (max-width: 1000px) {
  .vault-bg3 {
    background-position: 80% 100%;
    background-color: rgba(0, 0, 0, 0.4);
    background-blend-mode: darken;
  }
}
.web3modal-modal-lightbox {
  z-index: 100 !important;
}

.vault-page {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #F2EEEC;
}

.vault-fg {
  display: flex;
  padding: 2em;
  padding-top: 7em;
  width: 100vw;
  max-width: 1600px;
  justify-content: space-evenly;
  align-items: center;
}
@media (max-width: 1000px) {
  .vault-fg {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
}

.vault-fg-disconnected {
  display: flex;
  position: absolute;
  padding: 2em;
  z-index: 10;
  padding-top: 10em;
  width: 100vw;
  justify-content: center;
}
@media (max-width: 1000px) {
  .vault-fg-disconnected {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.marketing-section {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.videoplayer-section {
  margin-top: 50px;
  align-self: center;
}
.videoplayer-section video {
  min-width: 100px;
  width: 250px;
}

.videoplayer-section-disconnected {
  justify-self: center;
  margin: 40px;
}
.videoplayer-section-disconnected video {
  min-width: 100px;
  width: 300px;
}

.vault-text {
  position: static;
  top: 115px;
  width: 100%;
  max-width: 600px;
  line-height: 1.3;
  align-self: center;
}
.vault-text h1 {
  margin: 0 0 15px 0;
}
@media (min-width: 1000px) {
  .vault-text {
    padding-right: 0px;
  }
  .vault-text h1 {
    font-size: 65px;
    line-height: 0.8;
  }
}
.vault-text button {
  color: #303030;
  border-color: #303030;
  cursor: pointer;
  margin-top: 10px;
}
@media (max-width: 1000px) {
  .vault-text {
    top: 1em;
    color: #E5E5E5;
    padding-top: 30px;
  }
  .vault-text button {
    border-color: #E5E5E5;
    cursor: pointer;
    color: #F2EEEC;
    width: 100%;
    background-color: rgba(242, 238, 236, 0.3);
  }
}

.wallet-connect-section {
  display: flex;
  height: 200px;
  flex-direction: column;
  align-self: flex-start;
  justify-content: space-around;
  text-align: center;
  background-color: rgba(242, 248, 246, 0.7);
  border-radius: 15px;
  border: 2px solid #555555;
  color: #303030;
  padding: 20px;
  margin-top: 50px;
}
.wallet-connect-section button {
  margin: 20px;
  padding: 10px;
  color: #303030;
  cursor: pointer;
  background-color: #999900;
  font-size: 20px;
}
@media (max-width: 1000px) {
  .wallet-connect-section {
    max-width: 500px;
    width: 100%;
  }
}

.vault-listing-section {
  line-height: 1.3;
  background-color: rgba(242, 238, 236, 0.7);
  border-radius: 15px;
  padding: 10px;
  width: 90%;
  min-width: 500px;
  max-width: 700px;
  border: 2px solid #555555;
}
.vault-listing-section h3 {
  font-size: 35px;
  margin: 10px;
}

.vault-listing {
  display: flex;
  border: 2px solid #555555;
  border-radius: 15px;
  margin: 10px;
  padding: 10px;
  position: relative;
}

.listing-image {
  flex: 1 1;
  width: 30%;
  height: 100px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50% 50%;
  border-radius: 3px;
  margin: auto 0;
  padding: 10px;
}
.listing-image.disabled {
  opacity: 0.5;
}
.listing-image.-bundle {
  background-image: url(/static/media/Bundle.8f7b561b.jpg);
}
.listing-image.-ninez {
  background-image: url(/static/media/Card-Ninez.6db469f8.jpg);
}
.listing-image.-null {
  background-image: url(/static/media/Card-Null.21095d58.jpg);
}
.listing-image.-dee {
  background-image: url(/static/media/Card-Tweedle-Dee.37eb71ea.jpg);
}
.listing-image.-dum {
  background-image: url(/static/media/Card-Tweedle-Dum.8d4d90c6.jpg);
}
.listing-image.-holder {
  background-image: url(/static/media/holder.a2809d8e.png);
  height: 70px;
}

.listing-text-section {
  display: flex;
  flex-direction: column;
  flex: 4 1;
  padding-left: 5px;
}
.listing-text-section.disabled {
  color: #555555;
}

.listing-title {
  font-size: 20px;
  font-weight: bold;
}

.listing-description {
  font-size: 20px;
}

.listing-description2 {
  font-size: 11px;
}

.listing-total {
  align-self: center;
  text-align: center;
  flex: 1 1;
  font-size: 24px;
}

.bundle-active {
  color: rgb(218, 88, 88);
}

.bundle-applied {
  align-self: center;
  font-size: 11px;
}

.totals-section {
  padding: 10px;
}

.totals-final {
  display: flex;
  font-size: 36px;
  font-weight: 300;
  justify-content: space-between;
  align-items: center;
  height: 80px;
}
.totals-final button {
  cursor: pointer;
  background-color: #999900;
  font-size: 24px;
  width: 200px;
  height: 80px;
}

.checking-out button {
  background-image: url(/static/media/spinning-loading.b213dc57.gif);
  background-size: 100% 100%;
}

.vault-bg1 {
  position: fixed;
  bottom: 0;
  left: 5%;
  width: 293px;
  height: 465px;
  z-index: 3;
  background-image: url(/static/media/layer1.5594bb65.png);
  background-size: 100% 100%;
}
@media (max-width: 1000px) {
  .vault-bg1 {
    display: none;
  }
}

.vault-bg2 {
  position: fixed;
  bottom: 20%;
  left: 20%;
  width: 173px;
  height: 91px;
  z-index: 2;
  background-image: url(/static/media/layer2.d5b8949e.png);
  background-size: 100% 100%;
}
@media (max-width: 1000px) {
  .vault-bg2 {
    display: none;
  }
}

.vault-bg3 {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 92%;
  background-size: auto 100%;
  background-position: 50% 100%;
  background-repeat: no-repeat;
  background-image: url(/static/media/vault_of_ancients_bg_cutout.e396e8fc.png);
}
@media (max-width: 1000px) {
  .vault-bg3 {
    background-position: 80% 100%;
    background-color: rgba(0, 0, 0, 0.4);
    background-blend-mode: darken;
  }
}

.empty-space {
  width: 50px;
  flex-shrink: 10;
}

.quantity-disabled {
  max-width: 200px;
  color: red;
  font-size: 10px;
  align-self: center;
  text-align: center;
}

.quantity-picker {
  display: flex;
  flex-direction: row;
  align-self: center;
  vertical-align: middle;
  padding: 20px;
  font-size: 40;
}
.quantity-picker button {
  padding: 0;
}

.quantity-input:focus {
  background: red;
}

.quantity-modifier,
.quantity-display {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: none;
}

.quantity-modifier {
  width: 2rem;
  background: #ffffff;
  border: 2px solid #555555;
  text-align: center;
  cursor: pointer;
}

.quantity-modifier:focus {
  outline: 0;
}

.left-modifier {
  border-right: 0;
  border-radius: 3px 0 0 3px;
}

.mod-disable {
  color: #adaaaa;
}

.right-modifier {
  border-left: 0;
  border-radius: 0 3px 3px 0;
}

.quantity-display {
  height: 50%;
  width: 4rem;
  padding: 0.5rem;
  font-size: 20px;
  border: 2px solid #656565;
  text-align: center;
}

.newsletter {
  width: 600px;
  max-width: 800px;
  height: 570px;
}
@media (max-width: 600px) {
  .newsletter {
    width: 350px;
    height: 600px;
  }
}
.web3modal-modal-lightbox {
  z-index: 100 !important;
}

.my-collection-page {
  position: relative;
  width: 100vw;
  height: 100vh;
  background-color: #F2EEEC;
}

.my-collection-fg {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2em;
  padding-top: 9em;
  width: 100%;
  color: rgb(30, 30, 30);
  background-image: url(/static/media/brushtroke_gray_rotated.feb884a8.png);
  background-repeat: no-repeat;
  background-size: 600px 150px;
  background-position: 0px 120px;
}
.my-collection-fg h1 {
  font-size: 3em;
}
.my-collection-fg .title {
  max-width: 500px;
}
.web3modal-modal-lightbox {
  z-index: 100 !important;
}
.web3modal-modal-lightbox {
  z-index: 100 !important;
}

.vote-page {
  position: relative;
  width: 100vw;
  height: 100vh;
  margin-top: 0px;
  /* Location of the image */
  background-image: url(/static/media/interior_2_brushstroke_small.2f5a22e8.png);
  /* Background image is centered vertically and horizontally at all times */
  background-position: bottom right;
  /* Background image doesn't tile */
  background-repeat: no-repeat;
  /* Background image is fixed in the viewport so that it doesn't move when
  the content's height is greater than the image's height */
  background-attachment: fixed;
  background-size: contain;
  /* Set a background color that will be displayed
  while the background image is loading */
  background-color: #F2EEEC;
  opacity: 0.9;
}
@media (max-width: 1000px) {
  .vote-page {
    background: none;
  }
}
@media (max-width: 1700px) and (max-height: 1000px) {
  .vote-page {
    background: none;
  }
}

.vote-page-connected {
  position: relative;
  width: 100vw;
  height: 100vh;
  margin-top: 0px;
  opacity: 0.9;
}

.top-section {
  display: flex;
  flex-direction: row;
}

.vote-section {
  display: flex;
  flex-direction: row;
  width: 100vw;
  margin-top: 100px;
}

.vote-section-connected {
  display: flex;
  flex-direction: row;
  width: 100vw;
  margin-top: 100px;
}
@media (max-width: 1000px) {
  .vote-section-connected {
    flex-direction: column;
  }
}

.info-section {
  display: flex;
  flex-direction: column;
  max-width: 800px;
}

.vote-title {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 30px 0 30px;
  line-height: 1;
  width: 100%;
  color: rgb(30, 30, 30);
  background-image: url(/static/media/brushtroke_gray_rotated.feb884a8.png);
  background-repeat: no-repeat;
  background-size: 700px 200px;
  background-position: 0px 0px;
}
.vote-title h1 {
  font-size: 4em;
}

.vote-text {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 160%;
  color: #303030;
  max-width: 600px;
  margin: 0 30px 0 30px;
  mix-blend-mode: normal;
}
.vote-text a {
  text-decoration: none;
}

.vote-connect-btn {
  max-width: 400px;
  margin: 30px;
  background: rgba(197, 168, 57, 0.9);
  border: 2px solid #000000;
  border-radius: 4px;
  font-size: 20px;
  cursor: pointer;
}

.vote-btn {
  max-width: 200px;
  background: rgba(197, 168, 57, 0.9);
  border: 2px solid #000000;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

.fluxpower-btn {
  max-width: 100px;
  background: #F2EEEC;
  border: 2px solid #000000;
  border-radius: 4px;
  font-size: 10px;
  cursor: pointer;
}

.vote-option-section {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: 30px;
}
@media (max-width: 1000px) {
  .vote-option-section {
    display: none;
  }
}

.vote-option-section-connected {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: 30px;
  margin-right: 20px;
}

.voted-section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 30px;
  margin-right: 20px;
}

.vote-option {
  display: flex;
  background: rgba(225, 225, 225, 0.73);
  border-radius: 8px;
  align-items: center;
  margin: 20px;
}

.vote-img {
  width: 20vw;
  height: auto;
  -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
          filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 2px;
  padding: 12px;
  object-fit: contain;
  object-position: 0 0;
}

.vote-img-connected {
  width: 16vw;
  min-width: 250px;
  min-height: 250px;
  height: 12vw;
  -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
          filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 4px;
  padding: 12px;
  object-fit: cover;
  object-position: 50% 0;
}

.option-info-section {
  display: none;
  flex-direction: column;
  justify-content: space-around;
  width: 0px;
  max-width: 0px;
  padding: 20px;
  color: white;
}

.option-info-section-connected {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 20px;
  height: 50%;
  max-width: 700px;
  color: #303030;
}
.option-info-section-connected p {
  padding-bottom: 10px;
}

.voting-power {
  margin: 30px;
  line-height: 1;
}
.voting-power h1 {
  font-size: 3.3em;
}

.flux-checker {
  margin: 35px;
  font-size: 12px;
}
.web3modal-modal-lightbox {
  z-index: 100 !important;
}

html, body, #app {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: "Lexend", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  background-color: #F2EEEC;
}

#app {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  line-height: 160%;
}

* {
  box-sizing: border-box;
}

.lock {
  overflow-y: hidden;
}

button {
  font-family: inherit;
  font-weight: bold;
  font-size: inherit;
  background: none;
  margin: 0;
  padding: 1em;
  border: 2px solid #000;
  border-radius: 4px;
  text-transform: uppercase;
  color: inherit;
}

button.light {
  border: 2px solid #F2EEEC;
  color: #F2EEEC;
  background: rgba(242, 238, 236, 0.2);
}

p {
  margin: 0;
  margin-bottom: 1em;
}

h1 {
  text-transform: uppercase;
}

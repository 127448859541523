@import '../theme.scss';

$vote_breakpoint: 1000px;

.vote-page {

    position: relative;
    //top: 110px;
    width: 100vw;
    height: 100vh;
    margin-top: 0px;

    /* Location of the image */
    background-image: url(../assets/interior_2_brushstroke_small.png);

    /* Background image is centered vertically and horizontally at all times */
    background-position: bottom right;

    /* Background image doesn't tile */
    background-repeat: no-repeat;

    /* Background image is fixed in the viewport so that it doesn't move when
    the content's height is greater than the image's height */
    background-attachment: fixed;

    background-size: contain;

    /* Set a background color that will be displayed
    while the background image is loading */
    background-color: $white;

    opacity: 0.9;

    @media (max-width: $vote_breakpoint) {
        background: none;
    }

    @media (max-width: 1700px) and (max-height: 1000px) {
        background: none;
    }
}

.vote-page-connected {

    position: relative;
    //top: 110px;
    width: 100vw;
    height: 100vh;
    margin-top: 0px;

    opacity: 0.9;
}

.top-section {
    display: flex;
    flex-direction: row;
}

.vote-section {
    display: flex;
    flex-direction: row;
    width: 100vw;
    margin-top: 100px;
}

.vote-section-connected {
    display: flex;
    flex-direction: row;
    width: 100vw;
    margin-top: 100px;
    
    @media (max-width: $vote_breakpoint) {
        flex-direction: column;
    }
}

.info-section {
    display: flex;
    flex-direction: column;
    max-width: 800px;
}

.vote-title {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 30px 0 30px;
    line-height: 1.0;

    width: 100%;
    color: rgb(30,30,30);

    background-image: url(../assets/brushtroke_gray_rotated.png);
    background-repeat: no-repeat;
    background-size: 700px 200px;
    background-position: 0px 0px;

    h1 {
        font-size: 4em;
    }
}

.vote-text {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 160%;
    color: $black;
    max-width: 600px;
    margin: 0 30px 0 30px;

    mix-blend-mode: normal;

    a {
        text-decoration: none;
    }
}

.vote-connect-btn {
    max-width: 400px;
    margin: 30px;
    background: rgba(197, 168, 57, 0.9);
    border: 2px solid #000000;
    border-radius: 4px;
    font-size: 20px;
    cursor: pointer;
}

.vote-btn {
    max-width: 200px;
    background: rgba(197, 168, 57, 0.9);
    border: 2px solid #000000;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
}

.fluxpower-btn {
    max-width: 100px;
    background: $white;
    border: 2px solid #000000;
    border-radius: 4px;
    font-size: 10px;
    cursor: pointer;
}

.vote-option-section {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items:center;
    margin-top: 30px;

    @media (max-width: $vote_breakpoint) {
        display: none;
    }
}

.vote-option-section-connected {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items:center;
    margin-top: 30px;
    margin-right: 20px;
}

.voted-section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items:center;
    margin-top: 30px;
    margin-right: 20px;
}

.vote-option {
    display: flex;
    background: rgb(225 225 225 / 73%);
    border-radius: 8px;
    align-items: center;
    margin: 20px;
}

.vote-img {
    width: 20vw;
    height: auto;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 2px;
    padding: 12px;
    object-fit: contain;
    object-position: 0 0;
}

.vote-img-connected {
    width: 16vw;
    min-width: 250px;
    min-height: 250px;
    height: 12vw;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 4px;
    padding: 12px;
    object-fit: cover;
    object-position: 50% 0;
}

.option-info-section {
    display: none;
    flex-direction: column;
    justify-content: space-around;
    width: 0px;
    max-width: 0px;
    padding: 20px;
    color: white;
}

.option-info-section-connected {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 20px;
    height: 50%;
    // width: 700px;
    max-width: 700px;
    color: $black;

    p {
        padding-bottom: 10px;
    }

}

.voting-power {
    margin: 30px;
    line-height: 1.0;

    h1 {
        font-size: 3.3em;
    }
}

.flux-checker {
    margin:35px;
    font-size: 12px;
}
@import './theme.scss';

html, body, #app {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    font-family: 'Lexend', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 16px;
    background-color: $white;
}

#app {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    line-height: 160%;
}

* {
    box-sizing: border-box;
}

.lock {
    overflow-y: hidden;
}

button {
    font-family: inherit;
    font-weight: bold;
    font-size: inherit;
    background: none;
    margin: 0;
    padding: 1em;
    border: 2px solid #000;
    border-radius: 4px;
    text-transform: uppercase;
    color: inherit;
}

button.light {
    border: 2px solid $white;
    color: $white;
    background: rgba(242, 238, 236, 0.2);
}

p {
    margin: 0;
    margin-bottom: 1em;
}

h1 {
    text-transform: uppercase;
}
.scrollview {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
}

.bg {
    width: 100%;
    height: 100%;
    position: fixed;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.fg {
    display: flex;
    width: 100%;
    height: 100%;
    position: fixed;
}

.bg0 {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width: 1500px) {
        transform: translateY(calc(((100% - 100vw) / 2) + 200px));
    }
    
}

video {
    width: 100%;
    min-width: 100vh;
    height: auto;

  }
